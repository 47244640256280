
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import JoinusTop from "@/components/Utility/JoinusTop.vue";
import JoinusModal from "@/components/JoinUs/JoinusModal.vue";
@Component({
  components: {
    // Navbar,
    // Footer,
    JoinusTop,
    JoinusModal,
  },
})
export default class JoinUs extends Vue {
  //data
  boo = false;
  //hook
  created() {
    this.boo = "__PRERENDER_INJECTED" in window;
  }
  mounted(): void {
    this.$bvModal.show("join-us-modal");
    let modals = document.querySelectorAll<HTMLElement>(
      "#join-us-modal___BV_modal_outer_"
    );
    
    if (modals.length > 1) {
      modals[1].setAttribute("style", "display:none");
    }
  }
}
